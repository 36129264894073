import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { isNullOrWhitespace } from "../../utils/HelperFunctions"

const CompricerLandingPage = ({ location }) => {
  //Keep this page for legacy reasons and redirect to new landing page.

  useEffect(() => {
    if (isNullOrWhitespace(location.search)) {
      navigate("/")
      return
    }

    navigate(`/s?${new URLSearchParams(location.search).toString()}`, {
      replace: true,
    })
  }, [location.search])

  return <></>
}

export default CompricerLandingPage
